.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name1 {
  /* white-space: nowrap; */
  line-height: 1.1;
  text-overflow: ellipsis;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  word-wrap: break-word;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 768px) {
  .repo-name1 {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}

.cardpost__image {
  position: relative;
  min-height: 13.3125rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cardsmall {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  margin-bottom: 2rem;
}

.authorimg {
  transform: translateY(50%);
  margin-left: 1.5625rem;
  position: absolute;
  bottom: 0;
  display: flex!important;
}

.authorimg a {
  box-shadow: 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
  display: block;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  text-indent: -9999px;
  width: 3.1875rem;
  height: 3.1875rem;
}

.cardText {
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.grid svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 0.875em;
  margin-right: 10px;
}

.grid span {
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 15px;
  float: left;
}